import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { SdfIcon } from "@synerg/react-components";
import { selectedArticle } from "../../store";
import LinkWithIcon from "../LinkWithIcon";
import { ARTICLES_BORDER_TOP } from "../../utility/constants";

function ArticleBrief({ article, navigatePath }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isLoggedIn = useSelector((state) => {
    return state.auth.isLoggedIn;
  });
  const displayContent = (article, e) => {
    dispatch(selectedArticle(article));
    if(e.target.innerText === "View")
        navigate(`${navigatePath}/${article._id}`);   
  };

  return (
    <div className={ARTICLES_BORDER_TOP}>
      <Link to={`${navigatePath}/${article._id}`} style={{textDecoration: 'none', color: 'inherit'}}>
      <div
        className="hover:bg-action-default-100 cursor-pointer px-2"
        onClick={(e) => displayContent(article, e)}
      >
        <section className="flex items-center py-4 text-md">
          <main className="w-3/5 xl:w-4/5  mr-12 xl:mr-64">
            <div className="text-action-primary text-lg" style={{ wordBreak: "break-word" }}>{article.title}</div>
            <div className="mt-2" style={{ wordBreak: "break-word" }}>
              {article.abstract}
            </div>
          </main>
          <div className="flex w-2/5">
            <div className="text-action-secondary w-4/5 invisible md:visible text-left">
              {article.date}
            </div>
            <div>
              <div className={`text-base md:pl-12`}>
                {article.isPublic || isLoggedIn ? (
                  <LinkWithIcon
                    icon="nav-move-right"
                    iconPosition="after"
                    onClick={() => displayContent(article)}
                  >
                    View
                  </LinkWithIcon>
                ) : (
                  <aside className="text-action-primary flex flex-row ml-2">
                    <div className="pr-1 pl-px">
                      <SdfIcon icon="status-locked" external />
                    </div>
                    <LinkWithIcon
                      icon="nav-move-right"
                      iconPosition="after"
                      onClick={() => displayContent(article)}
                    >
                      View
                    </LinkWithIcon>
                  </aside>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      </Link>
    </div>
  );
}

export default ArticleBrief;