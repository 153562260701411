import { SdfIcon, SdfLink } from "@synerg/react-components";

import "./ExpertConsulting.css";

const ExpertConsulting = () => (
  <div className="w-full bg-action-default-50">
    <div className="w-10/12 md:w-4/6 flex flex-col m-auto py-12">
      <h2 className="mx-auto my-0 text-heading-06">
        Available consulting and development options
      </h2>
      <p className="mx-auto my-8 text-body-03">
        If you don’t have in-house developer resources, or your team needs more
        guidance, API Central and data automation are still within reach.
      </p>
      <div className="relative flex flex-col lg:flex-row gap-32 expert-consulting-point-container">
        <div>
          <SdfIcon icon="action-view-settings" className="text-2xl pb-1" />
          <h3 className="m-0 text-heading-04">
            Need help planning your integrations?
          </h3>
          <div className="m-0 text-body-02">
            ADP API specialists can guide you through your development efforts.
            Consulting includes selecting appropriate APIs, guidance on
            interacting with the applications you’re integrating with, and
            development best practices to complete your project quickly.
          </div>
        </div>
        <div>
          <SdfIcon icon="action-view-comment" className="text-2xl pb-1" />
          <h3 className="m-0 text-heading-04">
            Don’t have in-house developer resources?
          </h3>
          <div className="m-0 text-body-02">
            Our experienced ADP Marketplace partners can help build your
            integrations from start to finish. Simply subscribe to API Central,
            then purchase an integration package or contact one of our partners
            for a custom quote depending on your needs.
          </div>
        </div>
      </div>
      <SdfLink
        href="https://apps.adp.com/en-US/apps/419897?cro=t2435"
        className="no-underline whitespace-nowrap pt-8 mx-auto"
      >
        <span>Contact ADP sales to discuss your options </span>
        <SdfIcon icon="nav-move-next" />
      </SdfLink>
    </div>
  </div>
);

export default ExpertConsulting;
