import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const offset = 100;
const waypointLoadDelay = 500;

const scrollToElement = (hash) => {
  const element = document.getElementById(hash.substring(1));
  if (element) {
    window.scrollTo({ top: element.offsetTop - offset, behavior: "smooth" });
  }
};

export default function ScrollToTop() {
  const location = useLocation();
  /** @type {ReturnType<typeof useState<typeof location>>} */
  const [lastLocation, setLastLocation] = useState();
  const [shouldScroll, setShouldScroll] = useState(null);

  useEffect(() => {
    if (location.hash && location.hash !== lastLocation?.hash) {
      setShouldScroll({ hash: location.hash });
    } else if (location.pathname !== lastLocation?.pathname) {
      setShouldScroll({})
    }
    setLastLocation(location);
  }, [location]);

  useEffect(() => {
    if (shouldScroll?.hash) {
      scrollToElement(shouldScroll.hash);
      setShouldScroll(null);
    } else if (shouldScroll) {
      window.scrollTo(0, 0);
      setShouldScroll(null);
    }
    
  }, [shouldScroll])

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => setShouldScroll({ hash: location.hash }), waypointLoadDelay);
    }
  }, []);

  return null;
}
