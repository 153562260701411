import { Link } from "react-router-dom";

import "./HeroImage.css";

/**
 * @typedef {{
 *  title: string,
 *  linkText: string,
 *  pathName: string,
 *  className: string,
 *  partnerLanding: boolean,
 *  ariaLabel: string,
 * }} HeroLinkProps
 */

/**
 * @param {HeroLinkProps} props
 */
const HeroLink = ({
  title,
  linkText,
  pathName,
  className,
  partnerLanding,
  ariaLabel,
  ...rest
}) => {
  const linkClassName = `absolute rounded-full bg-default xl:px-6 lg:px-6 px-4 py-1 shadow-8 no-underline whitespace-nowrap${
    className ? ` ${className}` : ""
  }`;

  return (
    <li {...rest}>
      <Link to={pathName} aria-label={ariaLabel} className={linkClassName}>
        <div className="flex xl:text-heading-02 lg:text-heading-02 md:text-heading-02 text-heading-01 text-default whitespace-nowrap">
          {partnerLanding ? <span className="hero-image">{title}</span> : title}
        </div>
        <div className="text-heading-01">{linkText}</div>
      </Link>
    </li>
  );
};

/**
 * @param {{
 *  src: string | File;
 *  alt: string;
 *  linkList: Array<HeroLinkProps>;
 *  partnerLanding: boolean;
 * }} props
 */
const HeroImage = ({ alt, linkList, partnerLanding, ...rest }) => (
  <div className="flex justify-center">
    <div className="relative inline-block">
      <div
        className={`${
          partnerLanding
            ? ""
            : " absolute pt-3 bg-action-destructive-50 hero-image-shape hero-image-outline"
        }`}
      />
      <ul className="list-none">
        {linkList.map((item, index) => (
          <HeroLink
            key={`hero-link-${index}`}
            {...item}
            partnerLanding={partnerLanding}
          />
        ))}
      </ul>
      <img
        className={
          partnerLanding
            ? "xl:max-h-lg md:w-lg sm:h-md h-sm"
            : "hero-image-shape h-xs w-xs object-cover"
        }
        alt=""
        {...rest}
      />
    </div>
  </div>
);

export default HeroImage;
